import React from 'react';
import { useCampaign } from '../contexts/CampaignContext';
import DisplayAvailablePlanesList from '../components/lists/DisplayAvailablePlanesList';

function AvailablePlaneList() {
  const { campaignData, availablePlanes } = useCampaign();

  if (!campaignData || !availablePlanes.length) {
    return <div>Loading...</div>;
  }

  const currentCampaignDate = {
    Day: campaignData.Day.Day,
    Month: campaignData.Day.Month,
    Year: campaignData.Day.Year,
  };

  return (
    <div>
      <h1 className="text-center">Available Planes</h1>
      <DisplayAvailablePlanesList planes={availablePlanes} currentCampaignDate={currentCampaignDate} />
    </div>
  );
}

export default AvailablePlaneList;
