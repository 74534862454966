import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RemainingSupplyGraph from '../RemainingSupplyGraph'; // Adjust the path as needed

// Map country names to their respective image filenames
const countryImages = {
  'United States': 'USAAF_Roundel.svg',
  'Great Britain': 'RAF_Roundel.svg',
  'Germany': 'Germany_Roundel.svg',
};

function DisplayAirfieldsList({ airfields, showActiveOnly }) {
  const renderRows = () => {
    // Filter airfields if showActiveOnly is true
    const filteredAirfields = showActiveOnly
      ? airfields.filter((airfield) => airfield.ActiveToday)
      : airfields;

    return filteredAirfields.map((item, index) => {
      const arrowStyle = { transform: `rotate(${item.RunwayBearing}deg)` };
      const countryImage = countryImages[item.Country] || 'default-placeholder.svg';

      return (
        <tr key={index}>
          <th scope="row">
            <Link
              to={`/airfield/${encodeURIComponent(item.Name)}`}
              state={{ airfield: item }}
              className="text-white text-decoration-none"
            >
              {item.Name}
            </Link>
          </th>
          <td className="text-center">
            <img
              src={`/images/country-icons/${countryImage}`}
              alt={item.Country}
              title={item.Country} // Tooltip to display country name
              style={{
                width: '30px',
                height: '30px',
                ...(countryImage === 'RAF_Roundel.svg' && { width: '32px', height: '32px' }),
              }}
            />
          </td>
          <td>
            <RemainingSupplyGraph
              supplyLevel={item.SupplyLevel}
              maxSupplyLevel={item.MaxSupplyLevel || 200}
            />
          </td>
          <td>
            <i
              className="fa-solid fa-arrow-up"
              style={arrowStyle}
              title={`Runway Bearing: ${item.RunwayBearing}°`}
            ></i>
            <span className="ps-2">{item.RunwayBearing}°</span>
          </td>
          <td>{item.RunwayIsConcrete ? 'Concrete' : 'Grass'}</td>
        </tr>
      );
    });
  };

  return (
    <table className="table table-bordered table-dark table-responsive table-striped">
      <thead>
        <tr>
          <th>Name</th>
          <th>Country</th>
          <th>Supply</th>
          <th>Runway</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
}

DisplayAirfieldsList.propTypes = {
  airfields: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      Country: PropTypes.string.isRequired,
      SupplyLevel: PropTypes.number.isRequired,
      MaxSupplyLevel: PropTypes.number,
      RunwayBearing: PropTypes.number.isRequired,
      RunwayIsConcrete: PropTypes.bool.isRequired,
      ActiveToday: PropTypes.bool.isRequired, // Ensure ActiveToday is included
    })
  ).isRequired,
  showActiveOnly: PropTypes.bool, // Determines whether to show only active airfields
};

DisplayAirfieldsList.defaultProps = {
  showActiveOnly: false, // Default to showing all airfields
};

export default DisplayAirfieldsList;
