import React, { createContext, useContext, useState, useEffect } from 'react';

const CampaignContext = createContext();

export const useCampaign = () => useContext(CampaignContext);

export const CampaignProvider = ({ children }) => {
  const [campaignData, setCampaignData] = useState(null);
  const [availablePlanes, setAvailablePlanes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCampaignData = async () => {
      const response = await fetch('https://m2.combatbox.net/big-normandy-campaign/big-normandy-campaign-latest.json.aspx');
      if (!response.ok) {
        throw new Error('Failed to fetch campaign data');
      }
      return response.json();
    };

    const fetchAvailablePlanes = async () => {
      const response = await fetch(`${process.env.PUBLIC_URL}/data/AvailableAirplanes.json`);
      if (!response.ok) {
        throw new Error('Failed to fetch available planes');
      }
      return response.json();
    };

    const fetchAllData = async () => {
      try {
        const [campaignData, planesData] = await Promise.all([fetchCampaignData(), fetchAvailablePlanes()]);
        setCampaignData(campaignData);
        setAvailablePlanes(planesData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <CampaignContext.Provider value={{ campaignData, availablePlanes }}>
      {children}
    </CampaignContext.Provider>
  );
};
