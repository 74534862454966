import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import RemainingSupplyGraph from '../../components/RemainingSupplyGraph';
import Carousel from 'react-bootstrap/Carousel';
import getPhotoMapping from '../../components/getPhotoMapping';

function AirfieldDetails() {
  const location = useLocation();
  const { name } = useParams();

  const airfield = location.state?.airfield;

  if (!airfield || airfield.Name !== decodeURIComponent(name)) {
    return <div>Airfield not found.</div>;
  }

  const photos = getPhotoMapping().getReconPhotos(airfield.Name);

  return (
    <div>
      <div className="container-fluid">
        <h2 className="text-start my-3">{airfield.Name}</h2>
        <h4 className="text-start">{`${airfield.Country}`}</h4>

        {/* Recon Photos Carousel */}
        <Carousel>
          {photos.length > 0 ? (
            photos.map((photo, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={`${process.env.PUBLIC_URL}/images/recon-photos/big-normandy/${encodeURIComponent(photo)}`}
                  alt={`Recon of ${airfield.Name}`}
                />
              </Carousel.Item>

            ))
          ) : (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={`${process.env.PUBLIC_URL}/images/recon-photos/NoReconPhotoAvailable.png`}
                alt="No Recon Photo Available"
              />
            </Carousel.Item>
          )}
        </Carousel>
      </div>


      <div className='container'>
        <div className="my-3">
          <RemainingSupplyGraph
            supplyLevel={airfield.SupplyLevel}
            maxSupplyLevel={airfield.MaxSupplyLevel || 200}
          />
        </div>

        {airfield.AvailableAirframes.length > 0 ? (
          <table className="table table-dark table-bordered table-striped">
            <thead>
              <tr>
                <th className="airfield-details-icon-column">Image</th>
                <th className="airfield-details-plane-name-column">Plane</th>
                <th>Availability</th>
              </tr>
            </thead>
            <tbody>
              {airfield.AvailableAirframes.map((airframe, index) => (
                <tr key={index}>
                  <td>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/aircraft-icons/${getPhotoMapping().getReconPhotos(airframe.Type)}`}
                      alt={airframe.Type}
                      width="100"
                    />
                  </td>
                  <td>{airframe.ReadableName || 'N/A'}</td>
                  <td>{airframe.NumberAvailable === -1 ? 'Unlimited' : airframe.NumberAvailable}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="airfield-closed-text text-start">
            <div>The airfield is closed for the day.</div>
            <div>You can land and finish missions at this field.</div>
            You can rearm, repair, and refuel at this field.
          </div>
        )}
      </div>
    </div>
  );
}

export default AirfieldDetails;
