import React, { useEffect, useState } from 'react';
import axios from 'axios';

function PilotStatsList() {
  const [pilotStats, setPilotStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'streak_current', direction: 'descending' });

  const pilotsPerPage = 50;

  useEffect(() => {
    const fetchPilotStats = async () => {
      try {
        const response = await axios.get('https://il2statsapi.combatbox.net/api/ironmanstats');
        const filteredData = response.data.filter(
          pilot => pilot.flight_time > 0 && pilot.dead === 0 && pilot.captured === 0
        );

        setPilotStats(filteredData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchPilotStats();
  }, []);

  const formatFlightTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  const getCoalitionName = (coalPref) => {
    switch (coalPref) {
      case 0:
        return (<div><img src={`${process.env.PUBLIC_URL}/images/country-icons/USAAF_Roundel.svg`} style={{ width: '20px' }} /></div>);
      case 1:
        return (<img src={`${process.env.PUBLIC_URL}/images/country-icons/USAAF_Roundel.svg`} style={{ width: '20px' }} />);
      case 2:
        return (<img src={`${process.env.PUBLIC_URL}/images/country-icons/Germany_Roundel.svg`} style={{ width: '20px' }} />);
      default:
        return 'Unknown';
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedPilots = [...pilotStats].sort((a, b) => {
    if (sortConfig.key) {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredPilots = sortedPilots.filter((pilot) =>
    pilot.nickname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const paginatedPilots = filteredPilots.slice(
    (currentPage - 1) * pilotsPerPage,
    currentPage * pilotsPerPage
  );

  const getSortArrow = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'ascending' ? '↑' : '↓';
  };

  const getHeaderWithIcon = (key, icon, width = '50px') => (
    <span>
      <img
        src={`${process.env.PUBLIC_URL}/images/pilot-stats-header-icons/${icon}`}
        style={{ width, cursor: 'pointer' }}
        alt={`${key} icon`}
        title={key} // Tooltip text
      />
      {getSortArrow(key)}
    </span>
  );
  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div>
      <div className="container">
        <h2 className="text-uppercase fw-bolder text-center pt-3">Pilot stats</h2>
        <input
          type="text"
          placeholder="Search by Pilot Name"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="form-control mb-3"
        />
      </div>
      <div className="container-fluid pilot-stats">
        <div className="table-responsive">
          <table className="table table-dark table-striped" style={{ tableLayout: 'fixed', width: '100%' }}>
            <thead>
              <tr>
                <th style={{ width: '20%' }} onClick={() => handleSort('nickname')}>
                  Pilot Name {getSortArrow('nickname')}
                </th>
                <th style={{ width: '10%' }} className="text-center" onClick={() => handleSort('coal_pref')}>
                  Coalition {getSortArrow('coal_pref')}
                </th>
                <th style={{ width: '10%' }} className="text-center" onClick={() => handleSort('streak_current')}>
                  {getHeaderWithIcon('streak_current', 'air_kills.svg', '60px')}
                </th>
                <th style={{ width: '10%' }} className="text-center" onClick={() => handleSort('streak_ground_current')}>
                  {getHeaderWithIcon('streak_ground_current', 'ground_kills.svg', '40px')}
                </th>
                <th style={{ width: '10%' }} className="text-center" onClick={() => handleSort('takeoff')}>
                  {getHeaderWithIcon('takeoff', 'takeoffs.svg', '60px')}
                </th>
                <th style={{ width: '10%' }} className="text-center" onClick={() => handleSort('flight_time')}>
                  {getHeaderWithIcon('flight_time', 'flight_time.svg', '30px')}
                </th>
                <th style={{ width: '10%' }} className="text-center" onClick={() => handleSort('khr')}>
                  {getHeaderWithIcon('khr', 'air_kills_per_hour.svg', '40px')}
                </th>
                <th style={{ width: '10%' }} className="text-center" onClick={() => handleSort('gkh')}>
                  {getHeaderWithIcon('gkh', 'ground_kills_per_hour.svg', '40px')}
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedPilots.map((pilot, index) => (
                <tr key={index}>
                  <td>{pilot.nickname}</td>
                  <td className="text-center">{getCoalitionName(pilot.coal_pref)}</td>
                  <td className="text-center">{pilot.ak_total}</td>
                  <td className="text-center">{pilot.gk_total}</td>
                  <td className="text-center">{pilot.sorties_total}</td>
                  <td className="text-center">{formatFlightTime(pilot.flight_time)}</td>
                  <td className="text-center">{pilot.khr}</td>
                  <td className="text-center">{pilot.gkhr}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

    </div>
  );
}

export default PilotStatsList;
