import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import RemainingSupplyGraph from '../RemainingSupplyGraph'; // Adjust path as needed

function DisplayObjectivesList({ objectives, showActiveOnly }) {
  const renderRows = () => {
    // Filter objectives if showActiveOnly is true
    const filteredObjectives = showActiveOnly
      ? objectives.filter((objective) => objective.ActiveToday)
      : objectives;

    return filteredObjectives.map((objective, index) => {
      const capturableClass = objective.CapturableToday ? 'capturable-row' : '';

      return (
        <tr key={index} className={`selectable-row ${capturableClass}`}>
          <th scope="row">
            <Link
              to={`/objective/${encodeURIComponent(objective.Name)}`}
              state={{ objective }}
              className="text-white text-decoration-none"
            >
              {objective.Name}
            </Link>
          </th>
          <td>
            <RemainingSupplyGraph
              supplyLevel={objective.SupplyLevel}
              maxSupplyLevel={objective.MaxSupplyLevel}
            />
          </td>
          <td className="text-capitalize">
            {objective.Type.charAt(0) + objective.Type.slice(1).toLowerCase()}
          </td>
        </tr>
      );
    });
  };

  return (
    <table className="table table-bordered table-dark table-responsive table-striped">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Supply</th>
          <th scope="col">Type</th>
        </tr>
      </thead>
      <tbody>{renderRows()}</tbody>
    </table>
  );
}

DisplayObjectivesList.propTypes = {
  objectives: PropTypes.arrayOf(
    PropTypes.shape({
      Name: PropTypes.string.isRequired,
      SupplyLevel: PropTypes.number.isRequired,
      MaxSupplyLevel: PropTypes.number.isRequired,
      Type: PropTypes.string,
      CapturableToday: PropTypes.bool,
      ActiveToday: PropTypes.bool.isRequired,
    })
  ).isRequired,
  showActiveOnly: PropTypes.bool, // Determines whether to show only active objectives
};

DisplayObjectivesList.defaultProps = {
  showActiveOnly: false, // Default to showing all objectives
};

export default DisplayObjectivesList;
