import React from "react";
import { useNavigate } from 'react-router-dom';
import DisplayPilotStreaksList from '../components/lists/DisplayPilotStreaksList';
import DisplayAirfieldsList from "../components/lists/DisplayAirfieldsList";
import DisplayObjectivesList from "../components/lists/DisplayObjectivesList";
import DisplayCampaignStatus from '../components/DisplayCampaignStatus';
import DisplayWarEffort from '../components/DisplayWarEffort';
import DisplayCampaignStoryEvents from '../components/DisplayCampaignStoryEvents';
import DisplayWeatherForecast from '../components/DisplayWeatherForecast';
import DisplaySquadStreaksList from "../components/lists/DisplaySquadStreakList";
import topFiveSquads from "../assets/topFiveSquads.json";
import MapSlider from "../components/MapSlider";
import { useEffect, useState } from "react";
import '../styles/Main/main.css';

function Main() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://m2.combatbox.net/big-normandy-campaign/big-normandy-campaign-latest.json.aspx")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((jsonData) => {
        console.log("Fetched JSON Data:", jsonData);
        setData(jsonData);
      })
      .catch((err) => setError(err.message));
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  const sortAndSlicePilots = (pilots) => {
    const sortedPilots = [...pilots].sort((a, b) => b.SuccessfulMissionStreak - a.SuccessfulMissionStreak);
    return {
      topFive: sortedPilots.slice(0, 5),
      remaining: sortedPilots.slice(5),
    };
  };

  const alliedPilots = sortAndSlicePilots(data.PilotStreaksAllied);
  const axisPilots = sortAndSlicePilots(data.PilotStreaksAxis);

  const alliedAirfields = data.Airfields.filter(airfield => airfield.Coalition === "Allies");
  const axisAirfields = data.Airfields.filter(airfield => airfield.Coalition === "Axis");

  const alliedObjectives = data.Objectives.filter(objective => objective.Coalition === "Allies");
  const axisObjectives = data.Objectives.filter(objective => objective.Coalition === "Axis");

  console.log(topFiveSquads);

  const { alliedSquads = [], axisSquads = [] } = topFiveSquads;


  return (
    <div className="main">


      <h2 className="text-uppercase fw-bolder text-center pt-3">
        Campaign Status
      </h2>

      <div className="container-fluid">
        <div className="row">
          {/* Mid Section */}
          <div className="col-lg-4 col-12 order-1 order-lg-2">
            <div className="row">
              <div className="container pt-2">
                <div className="text-uppercase text-start text-subtitle">
                  Map
                </div>
                <MapSlider maxDay={data.Day.DayInCampaign} showSlider={true} showAnimationControls={true} />
              </div>
            </div>
            <div className="row pt-2">
              <div className="container">
                <DisplayCampaignStatus campaignData={data} />
              </div>
            </div>

            <div className="row pt-2">
              <div className="container">
                <DisplayWeatherForecast weather={data} />
              </div>
            </div>

            <div className="row pt-2">
              <div className="container">
                <DisplayCampaignStoryEvents campaignData={data} />
              </div>
            </div>

            <div className="row pt-2">
              <div className="container">
                <DisplayWarEffort campaignData={data} />
              </div>
            </div>
          </div>

          {/* Allied Section */}
          <div className="col-lg-4 col-12 order-2 order-lg-1">
            <h4 className="text-uppercase fw-bolder text-center pt-3">
              ALLIES Area of Operations
            </h4>
            <div className="text-uppercase text-start">Allied Airfields</div>
            <DisplayAirfieldsList airfields={alliedAirfields} showActiveOnly={true} />

            <div className="text-uppercase text-start mt-3">Allied Objectives</div>
            <DisplayObjectivesList objectives={alliedObjectives} showActiveOnly={true} />

            <div className="text-start mt-3">
              <button
                className="btn btn-dark"
                onClick={() =>
                  navigate(`/full-list/Allied`, {
                    state: { airfields: alliedAirfields, objectives: alliedObjectives, maxDay: data.Day.DayInCampaign },
                  })
                }
              >
                Show Full List
              </button>
            </div>
          </div>

          {/* Axis Section */}
          <div className="col-lg-4 col-12 order-3">
            <h4 className="text-uppercase fw-bolder text-center pt-3">
              AXIS area of OPerations
            </h4>
            <div className="text-uppercase text-start">Axis Airfields</div>
            <DisplayAirfieldsList airfields={axisAirfields} showActiveOnly={true} />
            <div className="text-uppercase text-start mt-3">Axis Objectives</div>
            <DisplayObjectivesList objectives={axisObjectives} showActiveOnly={true} />


            <div className="text-start mt-3">
              <button
                className="btn btn-dark"
                onClick={() =>
                  navigate(`/full-list/Axis`, {
                    state: { airfields: axisAirfields, objectives: axisObjectives, maxDay: data.Day.DayInCampaign },
                  })
                }
              >
                Show Full List
              </button>
            </div>
          </div>

        </div>
      </div>


      <div className="full-width-bar mt-4"></div>      

      <div className="container-fluid text-center">
        <h2 className="text-uppercase fw-bolder text-center pt-3">
          Top Successful Mission Streaks
        </h2>

        <div className="container-fluid">
          <div className="row gx-4">
            <div className="col-md-3 col-12 pb-3">
              <DisplayPilotStreaksList
                coalition="Allied"
                pilotTopFive={alliedPilots.topFive}
                pilotRemaining={alliedPilots.remaining}
              />
            </div>

            <div className="col-md-3 col-12 pb-3">
              <DisplaySquadStreaksList
                coalition="Allied"
                squads={alliedSquads}
              />
            </div>

            <div className="col-md-3 col-12 pb-3">
              <DisplaySquadStreaksList
                coalition="Axis"
                squads={axisSquads}
              />
            </div>


            <div className="col-md-3 col-12 pb-3">
              <DisplayPilotStreaksList
                coalition="Axis"
                pilotTopFive={axisPilots.topFive}
                pilotRemaining={axisPilots.remaining}
              />
            </div>
          </div>
        </div>
      </div>



    </div>
  );
}

export default Main;
