import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DisplayAirfieldsList from '../../components/lists/DisplayAirfieldsList';
import DisplayObjectivesList from '../../components/lists/DisplayObjectivesList';
import MapSlider from '../../components/MapSlider';

function AllAirfieldsAndObjectivesList() {
  const { state } = useLocation(); // Access state from navigate
  const { coalition } = useParams();

  // Default to empty arrays if state is undefined
  const airfields = state?.airfields || [];
  const objectives = state?.objectives || [];
  const maxDay = state?.maxDay || 1; // Fallback to 1 if maxDay is not provided

  return (
    <div className="container-fluid main">
      <h2 className="text-uppercase fw-bolder text-center pt-3">
        All owned {coalition} airfields and objectives
      </h2>
      <div className='row'>
      <div className='col-lg-5 col-12'>
        <div className="mt-4">
          <div className="text-uppercase fw-bolder ps-1">Airfields</div>
          <DisplayAirfieldsList airfields={airfields} />
        </div>
        <div className="mt-4">
          <div className="text-uppercase fw-bolder ps-1">Objectives</div>
          <DisplayObjectivesList objectives={objectives} />
        </div>
      </div>
      <div className="col-lg-7 col-12">
        <div className="mt-4">
          <div className="text-uppercase fw-bolder text-start">Campaign Map</div>
          <MapSlider maxDay={maxDay} showSlider={false} showAnimationControls={false} />

        </div>
      </div>
      </div>
    </div>
  );
}

export default AllAirfieldsAndObjectivesList;
