import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CustomModal from './Modal'; // Import your custom modal

function MapSlider({ maxDay, showSlider = true, showAnimationControls = true }) {
    const [sliderValue, setSliderValue] = useState(maxDay);
    const [isAnimating, setIsAnimating] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalImageUrl, setModalImageUrl] = useState('');
    const [intervalId, setIntervalId] = useState(null);

    const formattedValue = String(sliderValue).padStart(3, '0');
    const imageUrl = `https://m2.combatbox.net/big-normandy-campaign/big-normandy-campaign-day-${formattedValue}-1200.jpg`;

    useEffect(() => {
        preloadCampaignImages(maxDay);
        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [maxDay, intervalId]);

    const preloadCampaignImages = (maxDay) => {
        const baseImageUrl = 'https://m2.combatbox.net/big-normandy-campaign/big-normandy-campaign-day-';
        const imageExtension = '-1200.jpg';
        for (let day = 1; day <= maxDay; day++) {
            const formattedDay = String(day).padStart(3, '0');
            const img = new Image();
            img.src = `${baseImageUrl}${formattedDay}${imageExtension}`;
        }
    };

    const toggleAnimation = () => {
        if (isAnimating) {
            clearInterval(intervalId);
            setIsAnimating(false);
        } else {
            const id = setInterval(() => {
                setSliderValue((prev) => (prev < maxDay ? prev + 1 : 1));
            }, 100);
            setIntervalId(id);
            setIsAnimating(true);
        }
    };

    const handleSliderChange = (e) => {
        setSliderValue(parseInt(e.target.value, 10));
    };

    const openImageModal = () => {
        setModalImageUrl(`https://m2.combatbox.net/big-normandy-campaign/big-normandy-campaign-day-${formattedValue}.jpg`);
        setShowModal(true);
    };

    return (
        <div className="px-2 pb-2 justify-content-center border">
            {/* Image with top overlay */}
            <div className="position-relative">
                <img
                    id="campaignImage"
                    src={imageUrl}
                    alt={`Campaign Day ${sliderValue}`}
                    className="img-fluid mt-3"
                    onClick={openImageModal}
                />
                <div
                    className="position-absolute top-0 start-50 translate-middle-x text-white text-center map-date"
                >
                    {sliderValue === maxDay
                        ? `Current Day - ${sliderValue}`
                        : `Day: ${sliderValue}`}
                </div>
            </div>

            {/* Controls at the bottom */}
            <div className="col-12 mt-3">
                {showSlider && (
                    <input
                        type="range"
                        min="1"
                        max={maxDay}
                        value={sliderValue}
                        onChange={handleSliderChange}
                        className="form-range"
                        id="myRange"
                    />
                )}

                {showAnimationControls && (
                    <div className="text-center">
                        <button onClick={toggleAnimation} className="btn btn-dark">
                            {isAnimating ? 'Stop Animation' : 'Animate Days'}
                        </button>
                    </div>
                )}
            </div>

            {/* Modal */}
            <CustomModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={`Day ${sliderValue}`}
                body={<img src={modalImageUrl} alt={`Day ${sliderValue}`} className="img-fluid" />}
                size="xxl"
            />
        </div>
    );
}

MapSlider.propTypes = {
    maxDay: PropTypes.number.isRequired,
    showSlider: PropTypes.bool, // Control visibility of the slider
    showAnimationControls: PropTypes.bool, // Control visibility of animation controls
};

export default MapSlider;
