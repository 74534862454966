import { NavLink } from 'react-router-dom';

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary" data-bs-theme="dark">
      <div className="container-fluid">
        <NavLink to="/" className="navbar-brand">
          <img
            style={{ height: '40px' }}
            src={`${process.env.PUBLIC_URL}/images/combat_box_final_2.png`}
            alt="Combat Box Logo"
          />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Main
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/pilots"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Pilots
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/server-info"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Server Info
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/plane-list"
                className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              >
                Plane List
              </NavLink>
            </li>
            <li className="nav-item">
              <a
                href="https://discord.gg/PQN65PP92H"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Discord
              </a>
            </li>
            <li className="nav-item">
            <a
                href="https://www.patreon.com/c/Combatbox"
                className="nav-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Donate
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
