import React from 'react';
import PropTypes from 'prop-types';
import getPhotoMapping from '../getPhotoMapping';
import MissionCalendar from '../MissionCalendar';
import '../../styles/DisplayAvailablePlanesList/DisplayAvailablePlanesList.css';

function DisplayAvailablePlanesList({ planes = [], currentCampaignDate }) {
    const countryDisplayNames = {
        GB: 'RAF',
        US: 'USAAF',
        DE: 'Luftwaffe',
    };

    const groupByAirframe = () => {
        if (!planes.length) return { Allied: { GB: {}, US: {} }, Axis: { DE: {} } };

        const grouped = { Allied: { GB: {}, US: {} }, Axis: { DE: {} } };

        planes
            .sort((a, b) => {
                const airframeComparison = a.Airframe.localeCompare(b.Airframe);
                if (airframeComparison !== 0) return airframeComparison;
                return new Date(a.MinDate) - new Date(b.MinDate);
            })
            .forEach(plane => {
                const countryGroup = grouped.Allied[plane.Country] || grouped.Axis[plane.Country];
                if (!countryGroup[plane.Airframe]) {
                    countryGroup[plane.Airframe] = [];
                }
                countryGroup[plane.Airframe].push(plane);
            });

        return grouped;
    };

    const renderPlaneTable = (airframe, planesForAirframe) => {
        const planeIcon = getPhotoMapping().getReconPhotos(airframe)?.[0] || 'default-plane-icon.png';

        return (
            <div className="my-5">
                <div>{airframe}</div>
                <img
                    src={`${process.env.PUBLIC_URL}/images/aircraft-icons/${encodeURIComponent(planeIcon)}`}
                    style={{ width: '500px' }}
                    alt={airframe}
                />
                <table key={airframe} className="table table-dark table-bordered">
                    <thead>
                        <tr>
                            <th className='text-center'>Date Available</th>
                            <th>Required Supply</th>
                            <th>Min Runway Length</th>
                            <th>Airframe Limit</th>
                            <th>Available Mods</th>
                            <th>Mods Excluded</th>
                        </tr>
                    </thead>
                    <tbody>
                        {planesForAirframe.map((plane, index) => {
                            const minDate = new Date(plane.MinDate);
                            const missionDay = minDate.getDate();
                            const missionMonth = minDate.getMonth() + 1;
                            const missionYear = minDate.getFullYear();

                            const campaignDate = new Date(
                                currentCampaignDate.Year,
                                currentCampaignDate.Month - 1,
                                currentCampaignDate.Day
                            );

                            const isAvailable = campaignDate >= minDate;
                            const availabilityText = isAvailable ? 'Available' : 'Not Available';
                            const availabilityClass = isAvailable ? 'text-success' : 'text-danger';

                            return (
                                <tr key={index}>
                                    <td>
                                        <div className='text-center'>
                                            <MissionCalendar
                                                missionDay={missionDay}
                                                month={missionMonth}
                                                year={missionYear}
                                            />
                                            <div className={availabilityClass}>{availabilityText}</div>
                                        </div>
                                    </td>
                                    <td>{plane.MinSupply === 0 ? 'None' : plane.MinSupply}</td>
                                    <td>{plane.MinRunwayLength === 0 ? 'None' : plane.MinRunwayLength}</td>
                                    <td>{plane.AirframeLimit === 0 ? 'Unlimited' : plane.AirframeLimit}</td>
                                    <td>
                                        {plane.INCLUDE
                                            ? plane.INCLUDE.split(',').map((item, i) => (
                                                <div key={i}>{item.trim()}</div>
                                            ))
                                            : 'None'}
                                    </td>
                                    <td>{plane.EXCLUDE || 'None'}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderGroupedTables = (groupedPlanes) => {
        return Object.entries(groupedPlanes).map(([groupName, countries]) => (
            <div key={groupName}>
                <h2 className="text-start text-uppercase fw-bolder mt-5">{groupName} Planes</h2>
                {Object.entries(countries).map(([country, airframes]) => (
                    <div key={country}>
                        <h3 className="text-start text-light">{countryDisplayNames[country]}</h3>
                        {Object.entries(airframes).map(([airframe, planesForAirframe]) =>
                            renderPlaneTable(airframe, planesForAirframe)
                        )}
                    </div>
                ))}
            </div>
        ));
    };

    const groupedPlanes = groupByAirframe();

    return <div className="container-fluid">{renderGroupedTables(groupedPlanes)}</div>;
}

DisplayAvailablePlanesList.propTypes = {
    planes: PropTypes.arrayOf(
        PropTypes.shape({
            Airframe: PropTypes.string.isRequired,
            Country: PropTypes.string.isRequired,
            MinDate: PropTypes.string.isRequired,
            MinSupply: PropTypes.number.isRequired,
            MinDistanceToFrontLine: PropTypes.number.isRequired,
            MinRunwayLength: PropTypes.number.isRequired,
            AirframeLimit: PropTypes.number.isRequired,
            INCLUDE: PropTypes.string,
            EXCLUDE: PropTypes.string,
        })
    ),
    currentCampaignDate: PropTypes.shape({
        Day: PropTypes.number.isRequired,
        Month: PropTypes.number.isRequired,
        Year: PropTypes.number.isRequired,
    }).isRequired,
};

export default DisplayAvailablePlanesList;
