import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Main from './pages/Main';
import Pilots from './pages/Pilots';
import ServerInfo from './pages/ServerInfo';
import PlaneList from './pages/AvailablePlaneList';
import Discord from './pages/Discord';
import Donate from './pages/Donate';
import Header from './components/Header';
import AirfieldDetails from './pages/DetailPages/AirfieldDetails';
import ObjectiveDetails from './pages/DetailPages/ObjectiveDetails';
import AllAirfieldsAndObjectivesList from './pages/DetailPages/AllAirfeildsAndObjectivesList';
import { CampaignProvider } from './contexts/CampaignContext';

function App() {
  return (
    <CampaignProvider>
      <Router>
        <Header />
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/airfield/:name" element={<AirfieldDetails />} />
            <Route path="/objective/:name" element={<ObjectiveDetails />} />
            <Route
              path="/full-list/:coalition"
              element={<AllAirfieldsAndObjectivesList />}
            />
            <Route path="/pilots" element={<Pilots />} />
            <Route path="/server-info" element={<ServerInfo />} />
            <Route path="/plane-list" element={<PlaneList />} />
            <Route path="/discord" element={<Discord />} />
            <Route path="/donate" element={<Donate />} />
          </Routes>
        </div>
      </Router>
    </CampaignProvider>
  );
}

export default App;

